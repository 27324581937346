import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/button/button'
import { Link } from 'gatsby'

const Module = ({ buttonText, image, link, text }) => {
  return (
    <Container image={image}>
      <Wrapper>
        <Title>{text}</Title>
        <ButtonContainer>
          <Button as={Link} to={link}>
            {buttonText}
          </Button>
        </ButtonContainer>
      </Wrapper>
    </Container>
  )
}

Module.propTypes = {
  buttonText: PropTypes.string,
  image: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

Module.defaultProps = {
  buttonText: 'Learn more',
}

const Container = styled.div`
  background-image: ${props => `url('${props.image}')`};
  background-position: 35%, 0;
  background-repeat: no-repeat;
  background-size: cover;
  height: 400px;
  margin-bottom: 0;
  position: relative;
  width: 100%;
`

const Wrapper = styled.div`
  box-sizing: border-box;
  left: 50%;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 85%;

  @media (min-width: 800px) {
    width: 75%;
  }
`

const Title = styled.h4`
  color: #fff;
  font-weight: 600;
  margin: 0 0 30px;
  text-align: left;
  text-transform: none;

  @media (min-width: 800px) {
    max-width: 40%;
  }
`

const ButtonContainer = styled.div`
  width: 200px;

  a {
    text-decoration: none;
  }
`

export default Module
