import React from 'react'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2L from 'components/typography/h2'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import YouTube from 'components/youtube'
import { FaCaretLeft } from '@react-icons/all-files/fa/FaCaretLeft'
import { FaCaretRight } from '@react-icons/all-files/fa/FaCaretRight'
import Layout from 'components/layout'
import Content from 'components/content'
import Module from 'components/modules/module'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const breadcrumbs = [
  {
    name: 'Home',
    schemaName: 'Hutson Inc',
    link: '/',
  },
  {
    name: 'Hutson Customer Portal',
    link: '/hutson-customer-portal/',
  },
  {
    name: 'Viewing Past Invoices',
    link: '/hutson-customer-portal/viewing-past-invoices/',
  },
]

const ViewingPastInvoices = ({ data: { heroImage } }) => {
  return (
    <Layout>
      <Helmet>
        <title>Hutson Customer Portal - Viewing Past Invoices | Hutson Inc</title>
        <meta
          name='description'
          content='Learn how to view past invoices with the Hutson Hutson Customer Portal.'
        />
        <meta name='keywords' content='hutson customer portal, invoices, transaction history' />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'VideoObject',
            'name': 'How to View Invoices - Hutson Hutson Customer Portal',
            'description':
              'Learn how to view past invoices with the Hutson Customer Portal. Visit our website to login or create an account.',
            'thumbnailUrl': 'https://i.ytimg.com/vi/BW8zmcumr_E/hqdefault.jpg',
            'uploadDate': '2018-09-21T15:14:00+00:00',
            'duration': 'PT1M20S',
            'embedUrl': 'https://www.youtube.com/embed/BW8zmcumr_E',
          })}
        </script>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <HeroImage title='Viewing Past Invoices' image={heroImage} breadcrumbs={breadcrumbs} />

      <Content>
        <YouTube videoId='BW8zmcumr_E' />

        <Controls>
          <NavControl direction='prev' title='Previous tutorial'>
            <Link to='/hutson-customer-portal/creating-an-account/'>
              <span className='nav-control-label'>Previous:&nbsp;</span>
              <FaCaretLeft aria-hidden='true' focusable='false' role='presentation' />
              <span className='nav-control-title'>How to Create an Account</span>
            </Link>
          </NavControl>
          <NavControl direction='next' title='Next tutorial'>
            <Link to='/hutson-customer-portal/ordering-parts/'>
              <span className='nav-control-label'>Next:&nbsp;</span>
              <span className='nav-control-title'>How to Order Parts</span>
              <FaCaretRight aria-hidden='true' focusable='false' role='presentation' />
            </Link>
          </NavControl>
        </Controls>

        <H2L>Video Transcription</H2L>
        <Transcript>
          One of the most commonly used features of the Hutson Hutson Customer Portal is viewing
          previous invoices. There are many different use cases. You can replace lost invoices, your
          bookkeeper can have their own account to view transactions so they don't have to chase you
          down for a paper copy, and you can view your expenses over a period of time. There are
          multiple options to filter down the invoices that you want to view. The easiest way for me
          is with the date range. The transaction days range from the last seven days all the way to
          2016, which is as far back as our data goes. I'm gonna pick the last 12 months. You can
          also use categories to view parts, rental, sales, and service invoices to break them down
          by category. I just selected the last 12 months and I'm gonna click search and below you
          can see all your invoices and the details listed out. You have your date, your invoice
          number, your category, and your amount. If you'd like to view a more detailed version of
          your invoice or print it off, you can click the red PDF icon on the left and it will open
          up the original invoice in a new tab. Here you will see the full detail of the original
          invoice or you can print it off up here.
        </Transcript>
      </Content>

      <Module
        text="Save time by adding the Hutson Customer Portal to your phone's home screen"
        buttonText='Learn more'
        link='/blog/hutson-customer-portal-phone-home-screen/'
        image='/uploads/hutson-customer-portal-home-screen.jpg'
      />
    </Layout>
  )
}

const Controls = styled.div`
  margin: 30px 0 40px;

  @media (min-width: 800px) {
    margin-bottom: 60px;
  }
`

const NavControl = styled.div`
  display: block;
  padding-top: 15px;
  text-align: left;
  width: 100%;

  a {
    color: #7d7d7d;
    text-decoration: none;

    :hover,
    :focus {
      text-decoration: underline;
    }
  }

  .nav-control-title,
  .nav-control-label {
    font-size: 1.2em;
    vertical-align: middle;
  }

  .nav-control-title {
    text-decoration: underline;
  }

  svg {
    display: none;
  }

  @media (min-width: 800px) {
    display: inline-block;
    padding-top: 0;
    text-align: ${props => (props.direction === 'next' ? 'right' : 'left')};
    width: 50%;

    .nav-control-label {
      display: none;
    }

    .nav-control-title {
      text-decoration: none;
    }

    svg {
      color: #aaa;
      display: inline-block;
      font-size: 1em;
      margin: 0 10px;
      vertical-align: middle;
    }
  }
`

const Transcript = styled.p`
  margin: 0;
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "customer-portal-bg.jpg" }) {
      ...FullWidthImage
    }
  }
`

export default ViewingPastInvoices
